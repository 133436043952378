// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cennik-js": () => import("./../../../src/pages/cennik.js" /* webpackChunkName: "component---src-pages-cennik-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-o-mnie-js": () => import("./../../../src/pages/o-mnie.js" /* webpackChunkName: "component---src-pages-o-mnie-js" */),
  "component---src-pages-pliki-js": () => import("./../../../src/pages/pliki.js" /* webpackChunkName: "component---src-pages-pliki-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../../../src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */),
  "component---src-pages-problem-js": () => import("./../../../src/pages/problem.js" /* webpackChunkName: "component---src-pages-problem-js" */),
  "component---src-pages-zabiegi-js": () => import("./../../../src/pages/zabiegi.js" /* webpackChunkName: "component---src-pages-zabiegi-js" */),
  "component---src-templates-article-article-js": () => import("./../../../src/templates/article/article.js" /* webpackChunkName: "component---src-templates-article-article-js" */),
  "component---src-templates-blog-blog-js": () => import("./../../../src/templates/blog/blog.js" /* webpackChunkName: "component---src-templates-blog-blog-js" */),
  "component---src-templates-problem-problem-js": () => import("./../../../src/templates/problem/problem.js" /* webpackChunkName: "component---src-templates-problem-problem-js" */)
}

